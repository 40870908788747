import header from "./modules/header"
import openclose from "./modules/openclose"
import "./modules/slider"
import { checkCookie } from "./utils/gate"

window.addEventListener('load', () => {
	header()
	openclose()
	checkCookie()
})
