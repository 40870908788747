import { qs, qsa } from "../utils/dom";

export default function () {
  const closingElements = qsa('[data-close]')
  const closeAllOpenElements = qsa('[data-open]')
  const showElements = qsa('[data-show]')

  if (!closingElements || !closeAllOpenElements || !showElements) return

  window.addEventListener('click', () => {
    if (event.target.getAttribute('data-close')) {
      let container = event.target.closest('section')
      qs(`[data-closeitem="${event.target.dataset.close}"]`, container).classList.remove('active')
    }

    if (event.target.closest('[data-open]')) {
      let container = event.target.closest('section')
      let activeItem = event.target.closest('[data-open]')
      if (activeItem.classList.contains('open-active')) {
        activeItem.classList.remove('open-active')
      } else {
        for (const item of qsa("[data-open].open-active", container)) {
          item.classList.remove('open-active')
        }

        for (const item of qsa("[data-show].open-active", container)) {
          item.classList.remove('open-active')
        }

        for (const item of qsa(`[data-open="${activeItem.dataset.open}"]`, container)) {
          item.classList.add('open-active')
        }

        for (const item of qsa(`[data-show="${activeItem.dataset.open}"]`, container)) {
          item.classList.add('open-active')
        }
      }
    }
  })
}
