import { qs } from "../utils/dom";

export default function () {
  const body = qs('body')
  const toggleMenu = qs('[data-toggle-menu]')
  const menu = qs(toggleMenu.dataset.toggleMenu)

  if (!toggleMenu || !menu) return

  toggleMenu.addEventListener('click', () => {
    body.classList.toggle('disabled-scroll')
    toggleMenu.classList.toggle('openned')
    menu.classList.toggle('openned')
  })
}
