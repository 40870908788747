import { qs, qsa } from './dom'

export const setCookie = (cookieKey, cookieValue, expirationDays) => {
  let expiryDate = '';

  if (expirationDays) {
    const date = new Date();

    date.setTime(`${date.getTime()}${(expirationDays || 30 * 24 * 60 * 60 * 1000)}`);

    expiryDate = `; expiryDate=" ${date.toUTCString()}`;
  }

  document.cookie = `${cookieKey}=${cookieValue || ''}${expiryDate}; path=/`;
}

export const getCookie = (cookieKey) => {
  let cookieName = `${cookieKey}=`;

  let cookieArray = document.cookie.split(';');

  for (let cookie of cookieArray) {

    while (cookie.charAt(0) == ' ') {
          cookie = cookie.substring(1, cookie.length);
      }

    if (cookie.indexOf(cookieName) == 0) {
          return cookie.substring(cookieName.length, cookie.length);
      }
  }
}

export const checkCookie = () => {
  if (qs('.with-form') && qsa('.gated').length) {
    const formId = qs('.with-form').id
    if (!getCookie(`${formId}`)) {
      qsa('.gated').forEach((element) => {
        const accessButton = document.createElement('a');
        accessButton.setAttribute('class', 'sk-btn sk-btn--primary btn-access');
        accessButton.setAttribute('href', '#');
        accessButton.innerHTML = 'Get access'; // some text to improve visualization

        // add the <a> element tree into the div#something
        element.appendChild(accessButton);
      });
    } else {
      qsa('.gated').forEach((element) => {
        element.classList.remove('gated')
      })
    }
  }
}