import { qs, qsa } from "../utils/dom";
import Swiper from "swiper";
import { Navigation, Autoplay, Grid, EffectFade, Thumbs } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/effect-fade';

const windowWidth = window.innerWidth;
const allSliders = qsa('.swiper');

if (allSliders) {
  let sliders = [];

  const slidersInit = function () {

    allSliders.forEach((item) => {

      if (item.classList.contains('columns-slider-container') && item.classList.contains('container-home')) {
        let slider = new Swiper(item, {
          modules: [Navigation, Autoplay, Grid],
          slidesPerView: 1,
          slidesPerGroup: 1,
          loop: true,
          speed: 1000,
          grid: {
            fill: 'row',
            rows: item.classList.contains('container-sk-col-4') ? 1 : 8,
          },
          navigation: {
            nextEl: '.columns-slider .swiper-button-next',
            prevEl: '.columns-slider .swiper-button-prev',
          },
          breakpoints: {
            1024: {
              slidesPerView: 'auto',
              grid: {
                fill: 'column',
                rows: 1,
              },
              autoplay: {
                delay: 3000,
                pauseOnMouseEnter: true,
              },
            },
          },
        });
        sliders.push(slider);
      }

      if (item.classList.contains('hero-text-slider--container')) {
        let slider = new Swiper(item, {
          modules: [Autoplay],
          slidesPerView: 1,
          loop: true,
          speed: 200,
          autoplay: {
            delay: 3000,
            reverseDirection: true,
          },
        });
        sliders.push(slider);
      }

      if (item.classList.contains('summits-slider-container')) {
        let thumbsSwiper = new Swiper(".summits-slider-buttons", {
          loop: true,
          slidesPerView: "auto",
          freeMode: true,
          watchSlidesProgress: true,
        });

        let slider = new Swiper(item, {
          modules: [Navigation, Thumbs],
          slidesPerView: 1,
          loopAddBlankSlides: true,
          loop: true,
          speed: 1000,
          navigation: {
            nextEl: '.swiper-button-next.outer',
            prevEl: '.swiper-button-prev.outer',
          },
          thumbs: {
            swiper: thumbsSwiper,
          },
        });
        sliders.push(slider);
      }

      if (item.classList.contains('marquee-slider--container')) {
        let slider = new Swiper(item, {
          modules: [Autoplay],
          slidesPerView: 'auto',
          loop: true,
          centeredSlides: true,
          speed: 10000,
          autoplay: {
            delay: 1,
            disableOnInteraction: false,
            reverseDirection: true,
          },
        });
        sliders.push(slider);
      }

      if (item.classList.contains('sk-slider--fade')) {
        let slider = new Swiper(item, {
          modules: [EffectFade, Autoplay],
          slidesPerView: 'auto',
          loop: true,
          allowTouchMove: false,
          effect: 'fade',
          fadeEffect: {
            crossFade: true,
          },
          autoplay: {
            delay: item.classList.contains('services-slider--text-container') ? 5000 : 3000,
            waitForTransition: false,
          },
        });
        sliders.push(slider);
      }

      if (item.classList.contains('sk-slider--slide')) {
        let slider = new Swiper(item, {
          modules: [Navigation, Autoplay],
          loop: true,
          slidesPerView: 'auto',
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          autoplay: {
            delay: 5000,
          },
        });
        sliders.push(slider);

        if (item.classList.contains('posts-featured-container')) {
          document.addEventListener('facetwp-loaded', () => {
            slider.update()
            slider.slideToLoop(0)
          });
        }

        if (item.classList.contains('review-slider--filterable')) {
          window.addEventListener('click', (event) => {
            if (event.target.matches('.review-slider--filter')) {
              qs(".review-slider--filters button.active").classList.remove('active');
              event.target.classList.add('active');
              for (const item of qsa("[data-filter]")) {
                if (!item.dataset.filter.includes(event.target.id)) {
                  item.classList.add('hidden');
                } else {
                  item.classList.remove('hidden');
                }
              }
              slider.update()
            }

            if (event.target.matches('.review-slider--filter-all')) {
              qs(".review-slider--filters button.active").classList.remove('active');
              event.target.classList.add('active');
              for (const item of qsa("[data-filter]")) {
                item.classList.remove('hidden');
              }
              slider.update()
            }
          });
        }
      }
    });
  };

  window.addEventListener("load", () => {
    slidersInit()
  });

  window.addEventListener("resize", () => {
    if (window.innerWidth != windowWidth) {
      slidersInit()
    }
  });
}
